nav {
  display: none;
}

.disabled-link {
  color: grey;
  &:hover {
    color: grey;
    text-decoration: line-through;
  }
}

@media screen and (min-width: 600px) {
  nav {
    display: block;
    font-family: "IBM Plex Mono", monospace;
    text-align: center;
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    z-index: 30;
    a {
      display: inline-block;
      padding: 30px 20px;
      font-size: 25px;
      font-weight: 700;
      z-index: 0;
      color: black;
      text-decoration: none;
      &:visited {
        color: black;
      }
      &:hover {
        color: dodgerblue;
        text-decoration: underline;
        transition: 0.3s;
      }
      &:active {
        text-decoration: none;
        color: black;
      }
      &:focus {
        text-decoration: black;
      }
    }
  }
}
