body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  display: none; }

.disabled-link {
  color: grey; }
  .disabled-link:hover {
    color: grey;
    text-decoration: line-through; }

@media screen and (min-width: 600px) {
  nav {
    display: block;
    font-family: "IBM Plex Mono", monospace;
    text-align: center;
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    z-index: 30; }
    nav a {
      display: inline-block;
      padding: 30px 20px;
      font-size: 25px;
      font-weight: 700;
      z-index: 0;
      color: black;
      text-decoration: none; }
      nav a:visited {
        color: black; }
      nav a:hover {
        color: dodgerblue;
        text-decoration: underline;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
      nav a:active {
        text-decoration: none;
        color: black; }
      nav a:focus {
        -webkit-text-decoration: black;
                text-decoration: black; } }

#foot {
  font-family: "IBM Plex Mono", monospace;
  padding: 10px;
  height: 60px;
  background-color: white; }

.ul-container {
  display: flex;
  margin-bottom: -100px;
  padding-left: 0;
  list-style: none;
  justify-content: center; }
  .ul-container a {
    margin: 0 25px;
    display: inline-block; }
  .ul-container i {
    color: black; }
    .ul-container i:hover {
      color: dodgerblue;
      -webkit-transition: 0.3s;
      transition: 0.3s; }

.reactJS {
  width: 50%;
  color: black;
  margin-top: 2%;
  margin-right: 0;
  visibility: hidden; }

@media screen and (min-width: 600px) {
  .ul-container {
    float: left; }
  .reactJS {
    float: right;
    visibility: visible; } }

@media screen and (max-width: 310px) {
  #foot {
    padding: 0;
    height: 80px; }
  .ul-container {
    padding-top: 25px; } }

#aboutPage {
  padding-top: 100px;
  padding-bottom: 19%;
  background: black;
  color: white;
  position: relative; }

.jbaltazar {
  margin: 0.5% 0.5%;
  border: 4px solid;
  height: 165px;
  width: 165px;
  border-top-color: yellow;
  border-right-color: purple;
  border-bottom-color: yellow;
  border-left-color: purple;
  border-radius: 50%; }
  .jbaltazar:hover {
    -webkit-animation: spin 9s linear 0s infinite;
            animation: spin 9s linear 0s infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); } }

.content {
  font-family: "IBM Plex Mono", monospace;
  padding: 2%; }

.bio {
  padding: 3%;
  font-family: "IBM Plex Mono", monospace; }

.myLinks a i {
  margin: 0 0.3%;
  font-size: 40px;
  color: #fff; }
  .myLinks a i:hover {
    color: #00a0ff;
    -webkit-transition: 0.3s;
    transition: 0.3s; }

.resume {
  color: white; }
  .resume:hover {
    color: dodgerblue; }

span.hoverResume {
  height: 0;
  color: white;
  position: relative;
  cursor: pointer; }
  span.hoverResume:after {
    content: attr(popuptext);
    border-radius: 3px;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    -webkit-transition: 500ms ease;
    transition: 500ms ease;
    white-space: nowrap;
    max-height: 0; }
  span.hoverResume:hover:after {
    opacity: 1;
    top: 1.2em;
    max-height: 200px;
    padding: 0.3em; }

#experience {
  padding-top: 65px;
  padding-bottom: 0;
  margin: 0 auto;
  color: black; }

.exp-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.rounded-circle {
  margin: 0.5% 0.5%;
  border: 4px solid;
  height: 165px;
  width: 165px; }

.university {
  text-align: left;
  font-family: "SchoolBell", sans-serif; }

#ed-font {
  font-family: "IBM Plex Mono", monospace; }

.exp-logo {
  border-radius: 50%;
  border-color: white; }

.col-left-exp {
  padding-top: 10px;
  padding-left: 25%;
  height: 200px;
  width: 20%;
  float: left; }

.left-exp {
  padding-top: 10px;
  height: 200px;
  width: 49%;
  float: left; }

.right-exp {
  height: 200px;
  width: 49%;
  float: right; }

.outer-exp {
  justify-content: center;
  height: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px 0 10px; }

.workExp {
  font-family: "Comfortaa", sans-serif;
  padding: 1% 0 2% 0;
  width: 67%; }

p {
  margin: 0;
  padding: 0; }

@media screen and (max-width: 1024px) {
  .col-left-exp {
    height: 200px;
    width: 47%;
    float: left; }
  .right-exp {
    height: 200px;
    width: 47%;
    float: right; }
  .workExp {
    padding: auto; }
  .col-left-exp {
    padding-left: 10%;
    height: 200px;
    width: 20%;
    float: left; }
    .col-left-exp img {
      height: 160px;
      width: 160px; }
  .exp-container {
    text-align: center;
    padding: 0 auto;
    margin: 0 auto; } }

@media screen and (max-width: 1024px) {
  .right-exp {
    height: 200px;
    width: 47%;
    float: right; }
  .workExp {
    padding: 5% 0; }
  .col-left-exp {
    height: 200px;
    width: 47%;
    padding: 0; }
    .col-left-exp img {
      height: 160px;
      width: 160px; }
  .exp-container {
    text-align: center;
    padding: auto;
    margin: auto; } }

@media screen and (max-width: 700px) {
  .right-exp {
    height: 200px;
    width: 47%;
    float: right; }
  .workExp {
    padding: 20% 10% 10% 0; }
  .col-left-exp {
    height: 200px;
    width: 47%;
    padding: 0; }
    .col-left-exp img {
      height: 160px;
      width: 160px; }
  .exp-container {
    text-align: center;
    padding: auto;
    margin: auto; } }

#skills {
  font-family: "IBM Plex Mono", monospace;
  background: black;
  color: white;
  padding-top: 10px;
  padding-bottom: 20px; }

.colored {
  font-size: 3rem;
  margin: 1px; }

.devicon-linux-plain,
.devicon-github-plain {
  font-size: 3rem;
  margin: 0px; }

.devicon-hashicorp-plain {
  font-size: 2em; }

.grid {
  margin: auto;
  padding: auto; }
  .grid img {
    width: 80px;
    height: 80px; }

.dev-cont {
  padding-bottom: 20px; }
  .dev-cont img {
    width: 60px;
    height: 60px;
    padding-bottom: 20px; }

@media screen and (max-width: 600px) {
  #skills {
    padding-bottom: 30%; }
  .colored {
    font-size: 60px;
    margin: auto; }
  .dev-cont {
    margin: auto;
    display: flex;
    padding: 0;
    flex-direction: column; }
    .dev-cont img {
      width: 60px;
      height: 60px;
      padding-bottom: 0; } }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

